// Welsh
export default{
    form: {
        startSurveyButton: "Dechreuwn",
        nextButton: "Nesaf",
        submitAnswerButton: "Cyflwyno Ateb",
        continueButton: "Parhau",
        finishButton: "Gorffen",
        headerPercentageCompletion: 'wedi cwblhau',
        headerQuestionsCompleted: 'cwestiynau',
        headerDefaultErrorMessage: 'Wps. Aeth rhywbeth o’i le.',
        brandingPoweredBy: 'Wedi’i bweru gan',
        brandingPromoWhySurvey: 'Pam arolygu pan gallwch <b>{product}</b>?',
        brandingPromoGetStarted: 'Dechreuwch heddiw!',
        submissionPending: "Yn cyflwyno eich ffurflen lais. Peidiwch â chau eich ffenestr porwr.",
        submissionCanCloseWindow: "Mae eich ffurflen lais wedi’i chyflwyno’n llwyddiannus. Gallwch gau’r ffenestr nawr 👍",
        mediaAttachmentOverlayCloseButton: "Cau",
        expiredFormHeader: "Diolch am alw heibio!",
        expiredFormMessage: "Mae’n edrych fel bod yr arolwg hwn wedi dod i ben. Cysylltwch â chrewr y ffurflen lais i’w hysbysu.",
        notPublishedFormHeader: "Nid yw’r ffurflen lais wedi’i chyhoeddi!",
        notPublishedFormMessage: "Mae’n edrych fel nad yw’r arolwg hwn wedi’i gyhoeddi. Cysylltwch â chrewr y ffurflen lais i’w hysbysu.",
        notPublishedFormRetryButton: "Ceisio Eto",
        errorFormHeader: "Wps!",
        errorFormMessage: "Mae’n bosibl bod rhywbeth wedi mynd o’i le. Rhowch gynnig arall arni yn nes ymlaen.",
        errorFormRetryButton: "Ceisio Eto",
        emptyFormHeader: "Diolch am alw heibio!",
        emptyFormMessage: "Wps! Mae’n ymddangos bod y ffurflen hon yn wag ar hyn o bryd heb unrhyw gwestiynau neu feysydd i’w llenwi. Ymddiheurwn am yr anghyfleustra. I gywiro hyn, cysylltwch â chrewr y ffurflen hon a’u hysbysu am y broblem. Diolch am eich dealltwriaeth a’ch cydweithrediad!",

        restoreFormHeader: "Mae’n ymddangos bod gennych ffurflen ar y gweill!",
        restoreFormMessage: "Hoffech chi barhau o’r man y gwnaethoch ei adael?",
        restoreFormButton: "Parhau â'r Ffurflen",
        restoreFormButtonNew: "Dechrau Eto",

        audioPermissionHeader: "Hoffech chi ateb â llais?",
        audioPermissionMessage: "Bydd siarad yn gwneud ateb yn gyflym ac yn hawdd.",
        audioPermissionAcceptButton: "Ie, rwyf eisiau",
        audioPermissionDenyButton: "Na, diolch",
        audioPermissionErrorHeader: "Nid yw’r meicroffon ar gael",
        audioPermissionErrorMessage: "Gwiriwch ganiatâd eich porwr i alluogi mynediad i’r meicroffon.",
        audioPermissionErrorButton: "Iawn",
        audioRecorderFailedUpload: "Methu llwytho’r recordiad",
        silenceDetectorError: "Alla i ddim eich clywed. Gwiriwch osodiadau eich meicroffon. Awgrym: Gallai fod yn eich meicroffon allanol.",
        audioRecorderButtonRecord: "Taro Recordio",
        audioRecorderButtonRecordMore: "Recordio Mwy",
        voiceResponseSelectionMessage: "Dewiswch sut hoffech chi ateb...",
        voiceResponseSelectionOrDivider: "neu",
        voiceResponseTextInputPlaceholder: "Teipio ateb",
        voiceResponseTextInputCharacterCounterMinMessage: "Rhowch o leiaf {min} cymeriad",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} cymeriad (lleiafswm {min} cymeriad)",
        voiceResponseTextInputCharacterValidationMessage: "{count} cymeriad (lleiafswm {min} cymeriad)",

        // checkbox
        checkboxValidationTooFew: "Rhaid i chi ddewis o leiaf {min} opsiwn(au)",
        checkboxValidationTooMany: "Rhaid i chi ddewis dim mwy na {max} opsiwn(au)",
        checkboxNoneOfTheAboveOption: "Dim un o’r uchod",
        checkboxInvalidAnswer: "Ateb Annilys.",

        // datepicker
        datePickerPlaceholder: "Dewiswch ddyddiad",

        // dropdown
        // email
        emailLabel: "E-bost",
        emailInvalid:"E-bost annilys.",
        // file-upload
        fileUploadPluginNameCamera: "Camera",
        fileUploadPluginNameCameraVideo: "Recordio fideo",
        fileUploadPluginScreenCast: "Cofnodi sgrin",
        fileUploadVideoImportFilesDefault: 'Recordio neu lwytho fideo trwy:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Dewiswch opsiwn recordio fideo:',
        fileUploadVideoImportFilesNoCamera: 'Taro botwm i gofnodi sgrin fideo',
        fileUploadVideoImportFilesNoScreenCast: 'Taro botwm i recordio fideo',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Dim opsiynau ar gael i recordio fideo',

        // matrix
        matrixValidationMessage: "Mae’r ateb yn ddilys.",
        matrixRow: "Rhes",
        matrixColumn: "Colofn",
        matrixRowRequired: "Mae’r rhes {rowTitle} yn orfodol.",
        matrixRadioGroup: "Grŵp radio",
        matrixCheckboxGroup: "Grŵp blwch ticio",
        matrixGroupRequired: "Ar gyfer y rhes {rowTitle}. {type} {groupTitle} yn orfodol.",
        matrixColumnRequired: "Ar gyfer y rhes {rowTitle}. Mae’r golofn {columnTitle} yn orfodol.",
        matrixColumnInvalid: "Ar gyfer y rhes {rowTitle}. Mae’r golofn {columnTitle} yn annilys.",
        matrixRequired: "Yn orfodol.",
        matrixNumericMustBeNumber: "Rhaid iddo fod yn rhif.",
        matrixNumericMustBeGreaterThenMin: "Rhaid iddo fod yn fwy na {min}.",
        matrixNumericMustBeLessThenMax: "Rhaid iddo fod yn llai na {max}.",
        matrixNumericMustBeInteger: "Rhaid iddo fod yn gyfanrif.",
        matrixNumericInvalidNumber: "Rhif annilys.",

        // name
        nameLabel: "Enw",
        nameInvalid: "Nodwch enw cyntaf ac enw olaf.",

        // nps
        npsNotLikely: "Dim yn debygol o gwbl",
        npsExtremelyLikely: "Hynod debygol",

        // numeric input
        numericInputRequired: "Yn orfodol.",
        numericInputMustBeNumber: "Rhaid iddo fod yn rhif.",
        numericInputMustBeGreaterThenMin: "Rhaid iddo fod yn fwy na {min}.",
        numericInputMustBeLessThenMax: "Rhaid iddo fod yn llai na {max}.",
        numericInputMustBeInteger: "Rhaid iddo fod yn gyfanrif.",
        numericInputInvalidNumber: "Rhif annilys.",
        numericInputPlaceholder: "Teipio rhif",

        // phone
        phoneInvalid: "Mae’r rhif ffôn yn annilys.",
        phoneCountrySelectorLabel: 'Cod gwlad',
        phoneCountrySelectorError: 'Dewiswch wlad',
        phoneNumberLabel: 'Rhif ffôn',
        phoneExample: 'Enghraifft:',

        // boolean
        booleanYesLabel: "Ie",
        booleanNoLabel: "Na",

        //questionStep
        questionStepAudioQuestionLabel: "Cwestiwn Llais",

        // errors
        invalidPhoneNumber: "{phone} yw rhif ffôn annilys.",
        invalidEmail: "{email} yw cyfeiriad e-bost annilys.",
        questionIsRequired: "Mae’r cwestiwn yn orfodol.",
        answerIsNotValid: "Nid yw’r ateb yn ddilys.",
        unfinishedProbingDialogError: "Gorffennwch y drafodaeth.",
        cannotResumePartialResponse: "Methu ailddechrau ymateb rhannol.",
        failedToSubmitForm: "Methu cyflwyno ffurflen. Gwiriwch eich cysylltiad rhyngrwyd a cheisiwch eto.",

        //language picker
        searchLanguage: "Chwilio Iaith",
    }
}
